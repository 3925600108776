<template>
  <purchase code="PUCR"></purchase>
</template>

<script lang="ts">
import Purchase from '@accessory/pages/stock/in/purchase.vue'

export default factory({
  components: { Purchase }
})
</script>
